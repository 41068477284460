<template>
	<div>
		<div class="flex justify-between mb-5">
			<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Список пользователей</h1>
			<div class="flex">
				<Button v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" text="Добавить пользователя" class="mr-2" icon="add" @click.native="openPopUp()" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="openExport = true" />
			</div>
		</div>
		<div class="flex flex-col relative">
			<div
				class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default h-1/4"
				:class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }"
				:style="users.length > 9 ? 'height: calc(100vh - 320px)' : filterOpen || filterOpen === 0 ? 'min-height:320px' : ''"
			>
				<!-- <div class="table w-full"> -->
				<div class="table w-full">
					<!-- Заголовки -->
					<div class="table-header-group" :class="{ tr1__sticky: rowsVisible > 9 }">
						<div class="thead table-row content-center">
							<div class="table-cell th-content border-b sm:border-gray-100 sm:relative text-center w-20">
								<span class="my-auto text-gray-400 cursor-pointer font-normal justify-between items-center"> П/П </span>
							</div>

							<div
								v-for="(item, key) in fields"
								:key="'A' + key"
								class="table-cell th-content border-b sm:border-gray-100 sm:relative"
								:style="item.width !== null ? 'width: ' + item.width : ''"
							>
								<span
									class="w-full my-auto text-gray-400 cursor-pointer font-normal flex justify-start items-center"
									:class="{ 'text-blue-500 underline': filterBy === key }"
									@click="switchFilterVisibility(key)"
								>
									<p>
										<span :class="{ 'border-b': params.filters.find((f) => f.fieldName === item.fieldName) }">
											{{ item.Name }}
										</span>
									</p>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
									<!-- eslint-disable max-len -->
									<svg class="sm:hidden" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M18.1436 28.1027C17.6841 28.1027 17.2245 27.9913 16.8068 27.7546C15.9574 27.2811 15.4421 26.4178 15.4421 25.443V21.154C15.4421 20.8895 15.2332 20.3742 15.0244 20.1097L12.0026 16.9347C11.4317 16.3638 11 15.3612 11 14.5953V12.7433C11 11.1976 12.1697 10 13.6597 10H24.3403C25.8024 10 27 11.1976 27 12.6597V14.4421C27 15.4169 26.443 16.4891 25.886 17.0461L22.3629 20.1654C22.1401 20.3603 21.9034 20.8477 21.9034 21.2515V24.7328C21.9034 25.6101 21.3742 26.5849 20.6641 27.0026L19.564 27.7128C19.1323 27.9774 18.6449 28.1027 18.1436 28.1027ZM13.6597 12.0888C13.3255 12.0888 13.0888 12.3673 13.0888 12.7433V14.5953C13.0888 14.7763 13.2837 15.2498 13.5065 15.4726L16.5979 18.7311C17.0714 19.3299 17.5309 20.2907 17.5309 21.154V25.443C17.5309 25.7215 17.7119 25.8747 17.8233 25.9304C17.9765 26.0139 18.2272 26.0696 18.45 25.9304L19.564 25.2063C19.6754 25.1227 19.8146 24.8442 19.8146 24.705V21.2237C19.8146 20.235 20.302 19.1349 20.9983 18.564L24.4517 15.5004C24.6327 15.3194 24.9112 14.7624 24.9112 14.4143V12.6597C24.9112 12.3534 24.6466 12.0888 24.3403 12.0888H13.6597Z"
											fill="#9193A5"
										/>
									</svg>
									<!-- eslint-enable max-len -->
								</span>
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:position="key === fields.length - 1 ? 'right' : 'left'"
									:filterOpen="filterOpen"
									:id-filter="key"
									:param="params"
									@query="getData"
									:fields="fields"
									:defaultSortField="'regionName'"
									:item="item"
								/>
							</div>
							<div v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="table-cell th-content border-b sm:border-gray-100 sm:relative">
								<span class="w-full my-auto text-gray-400 font-normal justify-between items-center">
									<p>Действие</p>
								</span>
							</div>
						</div>
					</div>
					<!-- /Заголовки -->

					<!-- Содержимое -->
					<div class="tbody table-row-group content-center">
						<div v-for="(item, ind) in users" :key="item.id" class="table-row sm:border-b item-center sm:border-gray-100 text-gray-500">
							<div class="table-cell border-b border-gray-100 text-center">
								<span v-if="params.currentPage === 0">
									{{ ind + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * params.pageSize + (ind + 1) }}
								</span>
							</div>
							<div class="table-cell border-b border-gray-100">
								<p>
									<span v-if="item.roles">{{ setName(item.roles[0], 'roles') }}</span>
								</p>
							</div>
							<div class="table-cell border-b border-gray-100">
								<p>
									<span>{{ `${item.fname} ${item.iname} ${item.oname}` }}</span>
								</p>
							</div>
							<div class="table-cell border-b border-gray-100">
								<p>
									<span>{{ item.login }}</span>
								</p>
							</div>
							<div class="table-cell border-b border-gray-100">
								<p>
									<span>{{ item.email }}</span>
								</p>
							</div>
							<div class="table-cell border-b border-gray-100 text-center">
								<span class="flex">
									<span title="Не подтвержден" v-if="!item.emailConfirmed" style="margin: 0 auto">
										<svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clip-path="url(#clip0_419_67593)">
												<path
													d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
													fill="#C10016"
												/>
												<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
												<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											</g>
											<defs>
												<clipPath id="clip0_419_67593">
													<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
												</clipPath>
											</defs>
										</svg>
									</span>
									<span title="Подтвержден" v-else style="margin: 0 auto">
										<svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
											<g clip-path="url(#clip0_391_67823)">
												<path
													d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
													fill="#27AE60"
												/>
												<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											</g>
											<defs>
												<clipPath id="clip0_391_67823">
													<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
												</clipPath>
											</defs>
										</svg>
									</span>
								</span>
							</div>
							<div class="table-cell border-b border-gray-100 text-center">
								<span class="flex">
									<svg class="m-auto" v-if="!item.isMainCurator" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_419_67593)">
											<path
												d="M13.4375 27.4375C6.025 27.4375 0 21.4125 0 14C0 6.5875 6.025 0.5625 13.4375 0.5625C20.85 0.5625 26.875 6.5875 26.875 14C26.875 21.4125 20.85 27.4375 13.4375 27.4375ZM13.4375 2.4375C7.0625 2.4375 1.875 7.625 1.875 14C1.875 20.375 7.0625 25.5625 13.4375 25.5625C19.8125 25.5625 25 20.375 25 14C25 7.625 19.8125 2.4375 13.4375 2.4375Z"
												fill="#C10016"
											/>
											<path d="M10 17.5625L17 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
											<path d="M17 17.5625L10 10.5625" stroke="#C10016" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0_419_67593">
												<rect width="26.875" height="26.875" fill="white" transform="translate(0 0.5625)" />
											</clipPath>
										</defs>
									</svg>
									<svg v-else class="m-auto" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_391_67823)">
											<path
												d="M13.5 27.4375C6.0875 27.4375 0.0625 21.4125 0.0625 14C0.0625 6.5875 6.0875 0.5625 13.5 0.5625C20.9125 0.5625 26.9375 6.5875 26.9375 14C26.9375 21.4125 20.9125 27.4375 13.5 27.4375ZM13.5 2.4375C7.125 2.4375 1.9375 7.625 1.9375 14C1.9375 20.375 7.125 25.5625 13.5 25.5625C19.875 25.5625 25.0625 20.375 25.0625 14C25.0625 7.625 19.875 2.4375 13.5 2.4375Z"
												fill="#27AE60"
											/>
											<path d="M8.0625 14.6177L12.5845 18.5625L19.0625 9.5625" stroke="#27AE60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
										</g>
										<defs>
											<clipPath id="clip0_391_67823">
												<rect width="26.875" height="26.875" fill="white" transform="translate(0.0625 0.5625)" />
											</clipPath>
										</defs>
									</svg>
								</span>
							</div>
							<div v-if="userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])" class="table-cell border-b border-gray-100">
								<span class="flex">
									<Icons class="icon-control mr-2" icon-name="pen" @click.native="editUser(item)" title="Редактировать" />
									<!-- <Icons class="mr-2 icon-control" icon-name="send" @click.native="sendEmail(item.login)" /> -->
									<Icons
										v-if="userIsInRoles(['Support', 'MZ_Employee'])"
										class="icon-control icon-danger"
										icon-name="trash"
										@click.native="deleteUser(item.id, ind)"
										title="Удалить"
									/>
								</span>
							</div>
						</div>
					</div>
					<center v-if="!loadingTable && users.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
				</div>

				<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
					<loadingComponent class="absolute top-1/2 left-1/2" />
				</div>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>
		</div>
		<PopUp :open="open" :title="popupTitle" @close="closePopup()">
			<div class="flex">
				<div class="w-full mr-5">
					<div class="input-box" :class="{ error: error.roles }">
						<label>Роль*</label>
						<v-select :error="error.roles" @input="changeRole()" v-model="user.roles[0]" label="label" :reduce="(option) => option.value" :options="roles"></v-select>
						<p class="error-text" v-if="error.roles">{{ error.roles }}</p>
					</div>
					<TextInput label="Е-mail*:" :error="error.email" v-model="user.email" />
					<TextInput label="Пароль*:" v-if="!update" :error="error.password" v-model="user.password" />
					<TextInput label="Имя*:" :error="error.iname" v-model="user.iname" />
					<TextInput label="Наименование общественной организации*:" v-if="osDisabled" :error="error.organizationName" v-model="user.organizationName" />
					<label class="flex label" v-if="osDisabled">
						<input class="hidden" type="file" name="" @change="addImage($event)" />
						<div class="ava" :style="{ backgroundImage: `url(https://apiosnok.minzdrav.gov.ru${user.logoUrl})` }">
							<Icons v-if="!user.logoUrl" icon-name="user" />
						</div>
						<div class="label-text">Загрузить фотографию</div>
					</label>
					<div class="input-box" v-if="(showCurator && mainCurator === null) || user.isMainCurator">
						<label class="flex label">Куратор <input type="checkbox" v-model="user.isMainCurator" class="ml-2" /></label>
					</div>
				</div>
				<div class="w-full">
					<div class="input-box">
						<label>Регион</label>
						<v-select
							:class="{ disabled: regionDisabled }"
							:disabled="regionDisabled"
							v-model="user.regionId"
							label="label"
							:reduce="(option) => option.value"
							:options="regions"
						></v-select>
					</div>
					<TextInput label="Логин*:" :error="error.login" v-model="user.login" />
					<TextInput label="Фамилия*:" :error="error.fname" v-model="user.fname" />
					<TextInput label="Отчество:" v-model="user.oname" />
					<TextInput label="Место работы:" v-model="user.workPlace" v-if="osDisabled" />
				</div>
			</div>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closePopup()" />
				<Button size="medium" text="Сохранить" :disabled="saveDesabled" @click.native="validation()" />
			</div>
		</PopUp>
		<PopUp :open="openConfirm" title="Вы уверены, что хотите сохранить пользователя?" @close="closeConfirmPopup()">
			<p>Перед сохранением пользователя необходимо скопировать пароль, чтобы отправить его пользователю после его регистрации в Системе. Вы уверены, что хотите сохранить пользователя?</p>
			<div class="flex mt-5">
				<Button size="medium" class="mr-3" color="white" text="Отмена" @click.native="closeConfirmPopup()" />
				<Button size="medium" text="Сохранить" :disabled="saveDesabled" @click.native="sendForm()" />
			</div>
		</PopUp>

		<PopUp :open="openExport" size="400px" title="Выберите поля для экспорта" @close="closeOpenExport">
			<div class="flex flex-col">
				<CheckboxGroup :options="fieldsDownloadExcell" :disabled="false" />
			</div>
			<div class="flex mt-5 justify-end">
				<Button size="smoll" class="mr-3" text="Экспорт" :load="loadToExcell" :disabled="loadToExcell" @click.native="downloadExcell(params, fieldsDownloadExcell)" />
				<Button size="smoll" color="white" text="Закрыть" @click.native="closeOpenExport()" />
			</div>
		</PopUp>
	</div>
</template>

<script>
import Icons from '@/components/Icons.vue';
import FilterComponent from '@/components/Filter.vue';
import PopUp from '@/components/PopUp.vue';
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
// Глобальные данные стора
import { getters, mutations, actions, methods } from '@/store/store';
// Локальные данные стора
import actionsLocal from '../store/actions';
import loadingComponent from '@/components/Loading.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';

export default {
	name: 'TableMain',
	components: {
		loadingComponent,
		Icons,
		Button,
		FilterComponent,
		PopUp,
		TextInput,
		CheckboxGroup
	},
	computed: {
		...getters
	},
	data() {
		return {
			openExport: false,
			open: false,
			openConfirm: false,
			rowsVisible: 100,
			totalRows: 0,
			totalCount: 0,
			loadingTable: false,
			showCurator: false,
			page: 0,
			regions: [],
			user: {
				login: '',
				logoUrl: '',
				email: '',
				regionId: '',
				roles: [''],
				workPlace: '',
				organizationName: '',
				iname: '',
				password: null,
				fname: '',
				oname: ''
			},
			error: {
				login: null,
				password: null,
				email: null,
				roles: null,
				fname: null,
				iname: null,
				organizationName: null
			},
			saveDesabled: false,
			filterOpen: null,
			filterBy: null,
			fieldsDownloadExcell: [
				{ title: 'Роль', id: 'Roles', checked: true },
				{ title: 'ФИО', id: 'FullName', checked: true },
				{ title: 'Логин', id: 'Login', checked: true },
				{ title: 'E-mail', id: 'Email', checked: true },
				{ title: 'Подтвержден', id: 'EmailConfirmed', checked: true },
				{ title: 'Куратор', id: 'MainCurator', checked: true }
			],
			fields: [
				{
					Name: 'Роль',
					fieldName: 'Roles',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-96',
					heightUl: '150px',
					itemKey: 'nameRF',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				// {
				// 	Name: 'Регион',
				// 	fieldName: 'regionName',
				// 	filterType: 2,
				// 	filterValueType: 1,
				// 	value1: '',
				// 	value2: null,
				// 	sortBy: null,
				// 	label1: 'По возрастанию',
				// 	label2: 'По убыванию'
				// },
				{
					Name: 'ФИО',
					fieldName: 'fullName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Логин',
					fieldName: 'login',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Е-mail',
					fieldName: 'email',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Подтвержден',
					fieldName: 'emailConfirmed',
					filterType: 1,
					filterValueType: 3,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию',
					width: '136px'
				},
				{
					Name: 'Куратор',
					fieldName: 'isMainCurator',
					filterType: 1,
					filterValueType: 3,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию',
					width: '97px'
				}
			],
			users: [],
			roles: [],
			upadte: false,
			loadToExcell: false,
			popupTitle: '',
			mainCurator: null,
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: '1038b985-5708-41ef-95cb-85ba7586702c'
					}
				],
				sortOption: {
					fieldName: 'isMainCurator',
					sortType: 1
				},
				pageSize: 100,
				currentPage: 0
			},
			update: false,
			osDisabled: false,
			regionDisabled: false,
			submitDisabled: true
		};
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		generatPass() {
			let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
			let passwordLength = 8;
			this.user.password = '';
			for (let i = 0; i <= passwordLength; i += 1) {
				let randomNumber = Math.floor(Math.random() * chars.length);
				this.user.password += chars.substring(randomNumber, randomNumber + 1);
			}
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		changeRole() {
			let role = this.user.roles[0].split('_');
			this.osDisabled = role[0] === 'OS';
			this.showCurator = role[0] === 'OGV';

			this.setPaperOperatorEmail();
		},
		changeRegion() {
			this.setPaperOperatorEmail();
		},
		setPaperOperatorEmail() {
			if (this.user.roles[0] === 'OGV_PaperOperator') {
				if (this.mainCurator !== null) {
					this.user.email = this.mainCurator.email;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
		},
		setName(id, type) {
			let regexp = /[а-яё]/i;
			let name = '';
			this[type].forEach((item) => {
				if (item.value === id) name = item.label;
			});
			return regexp.test(id) ? id : name;
		},
		openPopUp() {
			this.update = false;
			this.generatPass();
			this.popupTitle = 'Добавить пользователя';
			this.open = true;
		},
		editUser(item) {
			let role = item.roles[0].split(' ');
			this.showCurator = role[1] === 'ОГВ';
			if (!item.roles) {
				item.roles = [''];
			}
			this.user = item;
			this.popupTitle = 'Редактировать пользователя';
			this.open = true;
			this.update = true;
		},
		deleteUser(id, index) {
			if (confirm('Вы уверены, что хотите удалить?')) {
				this.delUser(id)
					.then((res) => {
						this.users.splice(index, 1);
						this.showNotification('Пользователь успешно удален!', 'success');
					})
					.catch((err) => {
						console.log(err);
						this.showNotification('Ошибка при удалении пользователя, обратитесь к Администратору!', 'error');
					});
			}
		},
		sendEmail(login) {
			let yea = confirm('Отправить подтверждение на E-mail?');
			if (yea) {
				this.sendEmailConfirmedCode(login);
			}
			/* eslint-enable */
		},
		closePopup() {
			this.open = false;
			this.user = {
				login: '',
				logoUrl: '',
				email: '',
				regionId: this.region.id,
				roles: [''],
				organizationName: '',
				photoUrl: '',
				fullName: ''
			};
			this.error = {
				login: null,
				password: null,
				email: null,
				roles: null,
				fname: null,
				iname: null,
				organizationName: null
			};
			this.getData(this.params, false);
		},
		closeConfirmPopup() {
			this.openConfirm = false;
		},
		addImage(event) {
			this.uploadLogo(event).then((res) => {
				this.user.logoUrl = res.data;
			});
		},
		validation() {
			this.error = {
				login: null,
				password: null,
				email: null,
				roles: null,
				fname: null,
				iname: null,
				organizationName: null
			};
			if (!this.user.fname) {
				this.error.fname = 'Введите фамилию';
			}
			if (!this.user.iname) {
				this.error.iname = 'Введите имя';
			}
			if (!this.user.password && !this.update) {
				this.error.password = 'Введите пароль';
			}
			if (!this.user.login) {
				this.error.login = 'Введите логин';
			}
			if (!this.user.email) {
				this.error.email = 'Введите email';
			}
			if (!this.user.roles[0]) {
				this.error.roles = 'Выберите роль';
			}
			if (!this.user.organizationName && this.osDisabled) {
				this.error.organizationName = 'Введите наименование';
			}
			let errors = Object.values(this.error).some((item) => {
				return item !== null;
			});
			if (!errors) {
				if (!this.update) {
					this.openConfirm = true;
				} else {
					this.sendForm();
				}
			}
		},
		sendForm() {
			this.setValidRoleName();

			if (this.user.roles[0] === 'OGV_PaperOperator') {
				this.user.emailConfirmed = true;
			}

			if (!this.update) {
				this.saveDesabled = true;

				this.addUser(this.user)
					.then((res) => {
						this.users.push(res.data);
						this.closeConfirmPopup();
						this.closePopup();
						this.saveDesabled = false;
						this.showNotification('Пользователь успешно создан!', 'success');
					})
					.catch((err) => {
						console.log(err);
						this.saveDesabled = false;
						this.showNotification('Ошибка при создании пользователя, обратитесь к Администратору!', 'error');
					});
			} else {
				this.saveDesabled = true;
				this.updateUser(this.user).then(() => {
					this.saveDesabled = false;
					this.closeConfirmPopup();
					this.closePopup();
					this.showNotification('Пользователь успешно отредактирован!', 'success');
				});
			}
		},
		setValidRoleName() {
			let role = this.roles.find((x) => this.user.roles[0] === x.label);
			if (role !== undefined) {
				this.user.roles[0] = role.value;
			}
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params, true);
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
				this.page = 0;
			}

			this.getOSMembers(params).then((res) => {
				this.loadingTable = false;
				this.users = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name], false);
		},
		closeOpenExport() {
			for (let i = 0; i < this.fieldsDownloadExcell.length; i += 1) {
				this.fieldsDownloadExcell[i].checked = true;
			}

			this.openExport = false;
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params, fieldsDownloadExcell) {
			this.loadToExcell = true;
			let columnList = fieldsDownloadExcell.filter((f) => f.checked).map((m) => m.id);

			this.$http
				.post(
					'User/GetUsersExcel',
					{
						...params,
						columns: columnList
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Пользователи.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
					this.closeOpenExport();
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		}
	},
	mounted() {
		this.generatPass();

		this.getRegions().then((res) => {
			this.regions = [];
			res.data.forEach((item) => {
				this.regions.push({ label: item.Name, value: item.Id });
			});
		});

		this.getRoles().then((res) => {
			this.fields[0].list = res.data;
			this.roles = [];
			res.data.forEach((item) => {
				let itemName = item.nameRF ? item.nameRF : item.name;
				this.roles.push({ label: itemName, value: item.name });

				if (!this.userIsInRoles(['Support', 'MZ_Employee'])) {
					this.roles = this.roles.filter((role) => {
						let names = role.value.split('_');
						return names[0] === 'OGV' || names[0] === 'OS';
					});
				}
			});
		});

		this.params.filters[0].value1 = this.region.id;

		if (this.userIsInRoles(['Support', 'MZ_Observer'])) {
			this.regionDisabled = false;
		} else {
			this.user.regionId = this.region.id;
			this.regionDisabled = true;
		}

		this.getMainCuratorForRegion(this.region.id).then((result) => {
			this.mainCurator = result.data || null;
		});

		this.getData(this.params, true);
	}
};
</script>

<style scoped>
.tr1_sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
}
</style>

<style>
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}
.ava {
	background: #f2f5ff;
	border-radius: 50%;
	width: 80px;
	overflow: hidden;
	margin-right: 20px;
	height: 80px;
	display: flex;
	background-size: cover;
	background-position: 50% 50%;
}

.ava .icon,
.ava img {
	margin: auto;
}

.th-content .table-cell,
.table-cell {
	padding: 20px;
}

.icon-control {
	cursor: pointer;
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	z-index: 40;
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}
.error-text {
	color: #e94b3d;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
}

.error .vs__dropdown-toggle {
	border-color: #e94b3d;
}
</style>

<style scoped>
.table-cell span {
	margin: auto 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-cell span.m-auto {
	margin: auto;
}
</style>
